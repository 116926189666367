import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import socketIOClient from "socket.io-client";
import URLs from "../../utils/urls";

// Images
import logo from "../../assets/images/header-logo.png";

// CSS
import "../../assets/css/home-page.css";

export default function HomeComponent({ isLoggedOut, notAuthorizedMessage }) {
	const [socket, setSocket] = useState(undefined);
	useEffect(() => {
		if (!window.socket) {
			setupSocket();
		}
	}, []);
	const setupSocket = () => {
		const socketObj = socketIOClient(URLs.socketEndpoint, {
			upgrade: true,
			transports: ["websocket", "polling"], //['websocket', 'polling']
		});
		window.socket = socketObj;

		setSocket(socketObj);
	};

	useEffect(() => {
		if (socket) {
			socket.on("connect", (data) => {
				console.log("Connected To WS");

				socket.emit("user-data", {
					user_id: window.playerId,
					team_id: window.teamId,
					session_id: window.sessionId,
					token: window.token,
				});
			});

			socket.on("session-status-changed", (data) => {
				if (data.status) {
					window.location.reload();
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket]);
	useEffect(() => {
		let htmlElement = document.getElementsByTagName("html");
		if (htmlElement.length > 0) {
			htmlElement[0].style.height = "100%";
		}

		let bodyElement = document.getElementsByTagName("body");
		if (bodyElement.length > 0) {
			bodyElement[0].style.height = "100%";
		}

		let rootElement = document.getElementById("root");
		rootElement.style.height = "100%";
	}, []);

	return (
		<div className="form-body">
			<div className="auth-container">
				<div className="img-holder">
					<div className="bg"></div>
					<div className="info-holder"></div>
				</div>
				<div className="form-holder">
					<div className="form-content">
						<div className="form-items">
							<div className="website-logo-inside">
								<a href="./">
									<div className="logo">
										<Image
											className="logo-size"
											src={logo}
											alt=""
										/>
									</div>
								</a>
							</div>
							<h3>
								Welcome to Simdustry Drag & Drop Platform.
							</h3>
							<p>
								{isLoggedOut
									? "You Logged out successfully"
									: `${notAuthorizedMessage}`}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
